import classNames from 'classnames';

import { ButtonType } from '@xemplo/button';
import { DawnCircleStop } from '@xemplo/icons';

import * as S from './no-access.styles';

export interface NoAccessProps {
  centered?: boolean;
  message?: string;
  onClick?: () => void;
}

export function NoAccess(props: Readonly<NoAccessProps>) {
  const { centered, message, onClick } = props;

  return (
    <S.Wrapper className={classNames({ centered })}>
      <S.NoAccessContainer>
        <DawnCircleStop />
        <S.Header>No Access</S.Header>
        {message ??
          'Your sign-in was successful but you don’t have permission to access this module.'}
        <S.LogoutButton
          type={ButtonType.Secondary}
          ariaLabel="Log out button"
          onClick={onClick}
        >
          Log out
        </S.LogoutButton>
      </S.NoAccessContainer>
    </S.Wrapper>
  );
}

export default NoAccess;
