// @ts-nocheck

/** @knipignore */
export type Instances = "dev" | "qa" | "staging" | "production";

const environment: any = {
  identity_baseurl:
    window.env?.REACT_APP_IDENTITY_URL ?? "https://id-dev.xemplo.team",
  baseurl:
    window.env?.REACT_APP_BASE_URL ??
    "https://globalpayroll-api-dev-fe.expedo.team",
  callback_url: window.env?.REACT_APP_REDIRECT_URL
    ? `${window.env.REACT_APP_REDIRECT_URL}/confirm-login`
    : "http://localhost:3000/confirm-login",
  redirect_url: window.env?.REACT_APP_REDIRECT_URL
    ? `${window.env.REACT_APP_REDIRECT_URL}/confirm-logout`
    : "http://localhost:3000/confirm-logout",
  insight_key: window.env?.REACT_APP_INSIGHT_KEY,
  start_app_url:
    window.env?.REACT_APP_START_APP_URL ??
    "https://start-dev.expedo.team/login",
  cdn_url:
    window.env?.REACT_APP_CDN_URL ??
    "https://globalpayroll-data.expedo.team/commondata-devfe",
  image_upload_url:
    window.env?.REACT_APP_LOGO_UPLOAD_URL ??
    "https://expedoidentitymanagement-dev.azurewebsites.net",
  instance: window.env?.REACT_APP_INSTANCE ?? "dev",
  tiny_mce_editor_key:
    window.env?.REACT_APP_TINY_MCE_EDITOR_KEY ??
    "2ttrggc0wc47i446ek4eu4yzwr7ppfc9ip1perzyqgncojp3",
  google_api_key:
    window.env?.REACT_APP_GOOGLE_API_KEY ??
    "AIzaSyCW9uspJ7WGFv1z1sQisk-d15JM4K9_scg",
  google_analytics_key:
    window.env?.REACT_APP_GOOGLE_ANALYTICS_KEY ?? "G-CBCK7B3SY9",
  directory_service_url:
    window.env?.REACT_APP_DIRECTORY_SERVICE_URL ??
    "https://api-dev.expedo.team",
};
export default environment;
