import { QueryObserverOptions } from '@tanstack/react-query';
import { CancelToken } from 'axios';

export interface Result<T> {
  resultCode: number;
  validationErrors2: ValidationErrors;
  result: T;
  /** @deprecated use ValidationErrors instead */
  totalRecords: number;
}

export type PagedResult<T> = Result<T[]> & {
  result: Paging<T>;
};

export interface ListPagedResult<T> {
  resultCode: number;
  validationErrors: ValidationErrors;
  result: Paging<T>;
  /** @deprecated use ValidationErrors instead */
  totalRecords: number;
}

// TODO: remove this type once BE is updated for all apis

export interface LegacyListPagedResult<T> {
  resultCode: number;
  validationErrors: ValidationErrors;
  result: T[];
  /** @deprecated use ValidationErrors instead */
  totalRecords: number;
}

export interface LegacyPagedResult<T> {
  resultCode: number;
  validationErrors: LegacyValidationErrors;
  result: T;
  /** @deprecated use ValidationErrors instead */
  totalRecords: number;
}

export type Paging<T> = {
  page: number;
  page_Size: number;
  total: number;
  rows: T[];
  total_Pages?: number;
};

export type ValidationErrors = {
  errors: {
    internalErrorCode: number;
    internalErrorMessage: string;
    internalErrorMessageToDisplay: string;
    description: string;
  }[];
};

export interface UpdateUserProfileInput {
  firstName: string;
  lastName: string;
}

export const SortDirection = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];

export type Sort = {
  [key: string]: SortDirection;
};

/** There seems to have only one type using this. Should find a way to remove this */
export type SortLegacy = {
  field?: string;
  order?: SortDirection;
};

/** TODO: Merge/replace the PagedRequest */
export interface QueryFilters {
  per_page: number;
  page?: number;
  sort?: SortLegacy;
  q?: unknown;
}

/**
 * This interface should replace the QueryFilters. Not
 * replacing this right now because we need to figure it out
 * this sort legacy thing first.
 */
export interface PagedRequest<T = Record<string, unknown>>
  extends Record<string, unknown> {
  page?: number;
  per_page?: number;
  sort?: Sort;
  q?: T;
}

/** Not sure what is this doing here. Seems incorrect */
export interface ListUserRolesInput {
  area: string;
  options?: QueryFilters;
  cancelToken?: CancelToken;
}

/** @deprecated use ValidationErrors instead */
export type LegacyValidationErrors = {
  errors: {
    code: number;
    description: string;
    validatedColumn: string;
  }[];
};

export type EncodeURIComponent = string | number | boolean;

export type QueryProps<TData, TQueryString = Record<string, unknown>> = {
  queryProps?: QueryObserverOptions<TData>;
  requestParams?: PagedRequest<TQueryString>;
};

export type QueryPropsWithId<TData, TQueryString = Record<string, unknown>> = QueryProps<
  TData,
  TQueryString
> & {
  id: string | number | null;
};
