import { useNavigate } from 'react-router';

import { PayrollRoutes } from '@xemplo/gp-routes';
import { NoAccess } from '@xemplo/no-access';

import { NoAccessPageContainer } from './no-access-page.styles';

export function NoAccessPage() {
  const navigate = useNavigate();
  return (
    <NoAccessPageContainer>
      <NoAccess centered onClick={() => navigate(`/${PayrollRoutes.Logout}`)} />
    </NoAccessPageContainer>
  );
}

export default NoAccessPage;
