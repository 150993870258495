import { FormProvider } from 'react-hook-form';

import { DawnTriangleAlert } from '@xemplo/icons';

import { ModalBodyProps } from './create-amendment-form.types';
import * as S from './modal.styles';
import { useCreateAmendmentForm } from './use-create-amendment-form';

export function ModalBody(props: Readonly<ModalBodyProps>) {
  const { methods, getFormStep, errorMessage } = useCreateAmendmentForm(props);
  return (
    <FormProvider {...methods}>
      <S.StyledForm>
        {getFormStep()}
        {errorMessage && (
          <S.ErrorMessage>
            <DawnTriangleAlert />
            {errorMessage}
          </S.ErrorMessage>
        )}
      </S.StyledForm>
    </FormProvider>
  );
}
export default ModalBody;
